import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import rfcheckout_1 from "../../../assets/products/rfcheckout_1.png";
import rfcheckout_2 from "../../../assets/products/rfcheckout_2.png";
import rfcheckout_3 from "../../../assets/products/rfcheckout_3.png";
const HowitsWorkSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box
      id="how-its-work-section"
      component="div"
      className={classes.howItsWorksSectionBox}
    >
      <Box pb={{ xs: 2, md: 5, lg: 5, xl: 5 }}>
        <Typography
          classes={{ root: classes.howItsWorkSectionTitle }}
          variant="h4"
          component="h4"
          align="center"
        >
          How it works
        </Typography>
      </Box>
      <Box component="div" className={classes.contentSectionBox}>
        <Box
          style={{ backgroundImage: `url(${rfcheckout_1})` }}
          className={classes.imageBox}
        ></Box>
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                1
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Catalog Management
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              Manage your catalog, item level inventory, assign serialized
              number to each item in your inventory, attach a simple tag to the
              merchandise before stocking item on the shelf
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box component="div" className={classes.contentSectionBoxRight}>
        <Box pr={4} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                2
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Self Checkout Point
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              User has to simply place their basket with full of merchandise on
              to the Self Check out platform built-in with reading technology,
              that automatically shows the checkout amount and prompts user for
              payment
            </Typography>
          </Box>
        </Box>

        <Box
          style={{ backgroundImage: `url(${rfcheckout_2})` }}
          className={classes.imageBox}
        ></Box>
      </Box>

      <Box component="div" className={classes.contentSectionBox}>
        <Box
          style={{ backgroundImage: `url(${rfcheckout_3})` }}
          className={classes.imageBox}
        ></Box>
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                3
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Mobile Payment / Apply Pay / Google Pay
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              Use your mobile phone to pay for the checkout amount. Its as easy
              as tapping on your phone to finish payment and walk out with your
              merchandise.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowitsWorkSectionComponent;
