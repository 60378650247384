import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import RoomIcon from "@material-ui/icons/Room";
import EmailIcon from "@material-ui/icons/Email";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import brandlogo from "../../assets/logo.png";
import clxs from "clsx";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    paddingTop: 80,
    paddingLeft: 80,
    paddingRight: 80,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  footerLinkTitle: {
    color: theme.palette.common.white,
  },
  footerAddress: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  footerIcon: {
    padding: 0,
    width: 20,
    height: 20,
    color: "gray",
    marginLeft: -5,
    marginTop: 3,
    marginRight: 10,
  },
  footerAddressLbl: {
    color: "gray",
    fontWeight: 300,
  },
  ul: {
    paddingLeft: 13,
  },
  li: {
    color: "gray",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  activeColor: {
    color: theme.palette.common.white,
  },
  brandLbl: {
    fontSize: `12px`,
    letterSpacing: `1px`,
    marginLeft: `30px`,
    marginTop: `-8px`,
    color: `lightgray`,
    //color: theme.palette.secondary.main,
  },
  copyRightLbl: {
    color: "gray",
    textAlign:'center !important'
  },
}));

const Footer = ({ tabIndex }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  useEffect(() => {
    setValue(tabIndex);
  }, [tabIndex]);

  const handleFooterLinks = (url, index) => {
    setValue(index);
    history.push(url);
  };

  return (
    <Box className={classes.footerSection} id="footer-section">
      <Grid container spacing={2}>
        <Grid item md={4} lg={4} xl={4} xs={12}>
          <Box
            style={{ marginLeft: "-15px" }}
            display="flex"
            flexDirection="column"
          >
            <NavLink to="/">
              <img alt="ogo" src={brandlogo} width={150} />
            </NavLink>
          </Box>

          <Box mt={2} className={classes.footerAddress}>
            <Box>
              <RoomIcon classes={{ root: classes.footerIcon }} />
            </Box>
            <Box flexGrow={1}>
              <Typography
                classes={{ root: classes.footerAddressLbl }}
                variant="subtitle2"
                component="span"
              >
                <b>USA Address</b> -1845, ROBINWOOD VILLAGE DRIVE, Gastonia,
                North Carolina, 28054, United States
              </Typography>
            </Box>
          </Box>
          <Box mt={2} className={classes.footerAddress}>
            <Box>
              <EmailIcon classes={{ root: classes.footerIcon }} />
            </Box>
            <Box flexGrow={1}>
              <Typography
                classes={{ root: classes.footerAddressLbl }}
                variant="subtitle2"
                component="span"
              >
                raghavendrasrinivasa@gmail.com
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} lg={4} xl={4} xs={6}>
          <Box pl={{ md: 5, lg: 5, xl: 5 }}>
            <Typography
              variant="subtitle1"
              component="span"
              className={classes.footerLinkTitle}
            >
              Products
            </Typography>
          </Box>
          <Box pl={{ md: 5, lg: 5, xl: 5 }}>
            <ul className={classes.ul}>
              <li
                onClick={() => handleFooterLinks("/rfbin", 1)}
                className={
                  value === 1
                    ? clxs(classes.li, classes.activeColor)
                    : classes.li
                }
              >
                Rfbin
              </li>
              <li
                onClick={() => handleFooterLinks("/rf-checkout", 2)}
                className={
                  value === 2
                    ? clxs(classes.li, classes.activeColor)
                    : classes.li
                }
              >
                Rf Checkout
              </li>
              <li
                onClick={() => handleFooterLinks("/rf-digital-shelf", 3)}
                className={
                  value === 3
                    ? clxs(classes.li, classes.activeColor)
                    : classes.li
                }
              >
                Rf Digital Shelf
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item md={4} lg={4} xl={4} xs={6}>
          <Box>
            <Typography
              variant="subtitle1"
              component="span"
              className={classes.footerLinkTitle}
            >
              Services
            </Typography>
          </Box>
          <Box>
            <ul className={classes.ul}>
              <li
                onClick={() => handleFooterLinks("/rf-iot", 4)}
                className={
                  value === 4
                    ? clxs(classes.li, classes.activeColor)
                    : classes.li
                }
              >
                IoT Services
              </li>
              <li
                onClick={() => handleFooterLinks("/rf-iot", 4)}
                className={
                  value === 4
                    ? clxs(classes.li, classes.activeColor)
                    : classes.li
                }
              >
                Healthcare system integration services
              </li>
              <li className={classes.li}>Custom RFID Solutions</li>
            </ul>
          </Box>
        </Grid>
      </Grid>
      <Box style={{textAlign:'center'}} pt={10} pb={5}>
        <Typography align="center" variant="caption" className={classes.copyRightLbl}>
          © {new Date().getFullYear()} SPEKTRUMN CORPORATION | All Rights
          Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
