import React, { Fragment } from "react";
import IntroSectionComponent from "../components/rfbin/intro_section";
import GallerySectionComponent from "../components/rfbin/gallery_section";
import VideoSectionComponent from "../components/rfbin/video_section";
import HowItsWorkSectionComponent from "../components/rfbin/how_its_work_section";

const RfbinSpecimenPage = () => {
  return (
    <Fragment>
      <IntroSectionComponent />
      <GallerySectionComponent />
      <VideoSectionComponent />
      <HowItsWorkSectionComponent />
    </Fragment>
  );
};

export default RfbinSpecimenPage;
