import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import DztImageGalleryComponent from "reactjs-image-gallery";

import rfbin_1 from "../../../assets/products/rfbin_1.png";
import rfbin_2 from "../../../assets/products/rfbin_2.png";
import rfbin_3 from "../../../assets/products/rfbin_3.png";

var data = [
  {
    url: rfbin_1,
    title: "",
    thumbUrl: rfbin_1,
  },
  {
    url: rfbin_2,
    title: "",
    thumbUrl: rfbin_2,
  },
  {
    url: rfbin_3,
    title: "",
    thumbUrl: rfbin_3,
  },
];

const GallerySectionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.gallerySectionMainBox}>
      <Typography
        classes={{ root: classes.gallerySectionTitle }}
        variant="h4"
        component="h4"
        align="center"
      >
        RFbin Product Gallery
      </Typography>
      <DztImageGalleryComponent
        hideDownload={true}
        hideRotate={true}
        images={data}
      />
    </Box>
  );
};

export default GallerySectionComponent;
