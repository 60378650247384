import React, { Fragment, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { Hidden } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TabMenuComponent from "./tab_menu";
import { NavLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import logo from "../../assets/logo.png";
//import MobileTabMenus from "./mobile_menu";

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    [theme.breakpoints.only("xs")]: {
      minHeight: 85,
    },
  },
  brandLogoBox: {
    minWidth: 100,
    height: "100%",
  },
  brandLogo: {
    width: 170,
    [theme.breakpoints.only("xs")]: {
      width: 200,
    },
  },

  nagigationMenu: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  menuIconRoot: {
    fontSize: 35,
    color: "white",
  },
  drawerPaperLeft: {
    width: 250,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
  },
  drawerCloseIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

const NavigationComponent = ({tabIndex}) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Fragment>
      <AppBar color="secondary" position="static">
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              style={{paddingLeft:0,color:'white'}}
              onClick={() => {
                setOpenDrawer(!openDrawer);
              }}
            >
              <MenuIcon
                classes={{ root: classes.menuIconRoot }}
                width={35}
                height={35}
              />
            </IconButton>
          </Hidden>

          <Box className={classes.brandLogoBox}>
            <NavLink to="/">
              <img
                className={classes.brandLogo}
                alt="logo"
                src={logo}
              />
            </NavLink>
          </Box>
          <Box className={classes.nagigationMenu}>
            <Hidden only={["xs", "sm"]}>
              <TabMenuComponent onClickTab={() => {}} tabIndex={tabIndex}/>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paperAnchorLeft: classes.drawerPaperLeft }}
        anchor="left"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <TabMenuComponent
          orientation="vertical"
          onClickTab={(value) => setOpenDrawer(value)}
        />

        <Box className={classes.drawerCloseIcon}>
          <IconButton
            onClick={() => {
              setOpenDrawer(!openDrawer);
            }}
          >
            <CloseIcon
              classes={{ root: classes.menuIconRoot }}
              width={35}
              height={35}
            />
          </IconButton>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default NavigationComponent;
