import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tabMenuRoot: {
    [theme.breakpoints.only("xs")]: {
      marginTop: 75,
    },
  },
  tabIndicator: {
    height: 50,
  },
  tabWrapper: {
    color: "#fafafa",
    fontSize: "initial",
  },
  tabSelected: {
    fontWeight: 500,
    color: "#FFFFFF",
  },
}));

const TabMenuComponent = ({ orientation, onClickTab, tabIndex }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setValue(tabIndex);
  }, [tabIndex]);

  const handleChange = (value, index) => {
    setValue(index);
  };

  return (
    <Tabs
      orientation={orientation}
      classes={{ root: classes.tabMenuRoot }}
      value={value}
      onChange={handleChange}
      aria-label="menu-tabs"
    >
      <Tab
        classes={{ selected: classes.tabSelected }}
        onClick={() => {
          history.push("/");
          onClickTab(false);
        }}
        label="Home"
      />
      <Tab
        classes={{ selected: classes.tabSelected }}
        onClick={() => {
          history.push("/rfbin");
          onClickTab(false);
        }}
        label="RFBin"
      />
      <Tab
        classes={{ selected: classes.tabSelected }}
        onClick={() => {
          history.push("/rf-checkout");
          onClickTab(false);
        }}
        label="RF Checkout"
      />
      <Tab
        classes={{ selected: classes.tabSelected }}
        onClick={() => {
          history.push("/rf-digital-shelf");
          onClickTab(false);
        }}
        label="RF Digital Shelf"
      />
      <Tab
        classes={{ selected: classes.tabSelected }}
        onClick={() => {
          history.push("/rf-iot");
          onClickTab(false);
        }}
        label="IoT Services"
      />
    </Tabs>
  );
};

export default TabMenuComponent;
