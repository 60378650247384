import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import rfbin from "../../assets/products/rfbin_1.png";
import rfbinCheckout from "../../assets/products/rfid_checkout.png";
import rfdigital from "../../assets/products/rfdigital_2.png";

const useStyles = makeStyles((theme) => ({
  serviceSection: {
    paddingTop: 80,
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 80,
    width: "100%",
    backgroundColor: "#F5F5F5",
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
      paddingTop: 40,
    },
  },
  serviceLbl: {
    textTransform: "uppercase",
    fontWeight: 400,
   // color: theme.palette.common.white,
  },

  serviceCardHero: {
    height: 250,
    position: "relative",
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  serviceCardContent: {
    backgroundColor: "white",
    padding: 30,
    marginTop: 5,
    [theme.breakpoints.only("xs")]: {
      padding: 20,
    },
  },
  serviceCardContentTitle: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  serviceCardContentSubTitle: {
    fontWeight: 400,
    //color: "#FAFAFA",
    fontSize: 14,
  },
  serviceTitleBadge: {
    position: "absolute",
    right: 0,
    bottom: 0,
    paddingLeft: 20,
    backgroundColor: theme.palette.secondary.main,
    minWidth: 250,
    // borderTopLeftRadius: 50,
    // borderTop: `2px solid #F5F5F5`,
    // borderLeft: `2px solid #F5F5F5`,
  },
  serviceTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  serviceCardTitle: {
    color: theme.palette.common.white,
    fontWeight: 300,
    paddingBottom: 10,
  },

  serviceCardSubTitle: {
    color: "gray",
    fontWeight: 300,
    paddingBottom: 10,
  },

  serviceCardImg: {
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    borderRadius: 25,
  },

  leftSideSection: {
    flex: 3,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  rightSideSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 2.5,
    paddingRight: 20,
  },
  rfidImage: {
    width: "100%",
  },
  patentRight: {
    backgroundColor: `brown`,
    color: `bisque`,
    padding: `3px`,
    fontSize: `12px`,
  },
}));

const ServiceCard = ({ imgUrl, title, subTitle, link, caption }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.23899982356223737) 0%, rgba(0,0,0,0.2978233529740021) 100%),url(${imgUrl})`,
        }}
        className={classes.serviceCardHero}
      >
        <Box pl={3} pt={1} pr={1} pb={1} className={classes.serviceTitleBadge}>
          <Typography
            classes={{ root: classes.serviceTitle }}
            align="center"
            variant="h6"
            component="h6"
          >
            {title}
          </Typography>
          {caption && <span className={classes.patentRight}> {caption}</span>}
        </Box>
      </Box>
      <Box className={classes.serviceCardContent}>
        <Typography
          classes={{ root: classes.serviceCardContentTitle }}
          variant="subtitle1"
          component="h6"
        ></Typography>
        <Typography
          className={classes.serviceCardContentSubTitle}
          variant="subtitle1"
          component="p"
        >
          {subTitle}
        </Typography>
        <Box mt={2}>
          <Button
            component={NavLink}
            disableElevation
            to={link}
            variant="contained"
            color="secondary"
          >
            View Details
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const ServiceComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.serviceSection}>
      <Box mb={8}>
        <Typography
          classes={{ root: classes.serviceLbl }}
          variant="h4"
          component="h4"
          align="center"
        >
          Our Products / Services
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item md={6} lg={6}>
          <ServiceCard
            link="/rfbin"
            caption="Patent Pending"
            title="Bio-sample Tracking Solution"
            subTitle="For independent labs keeping the chain of custody of Bio-sample  from collection point to testing to providing results back to their customers, is a crucial part of their workflow. Currently there is a huge gap exists at the collection point, this lack of information at collection point costing Independent Labs loss of revenue, loss of customers, etc.. "
            imgUrl={rfbin}
          />
        </Grid>
        <Grid item md={6} lg={6}>
          <ServiceCard
            link="/rf-checkout"
            title="Self Checkout for Retail"
            subTitle="Touchless checkout, give your customers speed and convenience of grab and go by eliminating the long lines, painful checkout process. No barcodes to scan, no attendant to see, grab the items place them on the designated checkout area, get total amount to checkout, pay using your mobile and walk out."
            imgUrl={rfbinCheckout}
          />
        </Grid>
        <Grid item md={6} lg={6}>
          <ServiceCard
            link="/rf-digital-shelf"
            title="Digital Shelf and Signage for Retail"
            subTitle="Digital Shelf Labels are a fully customizable e-paper display, highlighting prices, advertisements, and any other desired information. Allows retails store in real-time optimize the price and precision, store efficiency, and enable trust between the retailer and customer through transparent pricing."
            imgUrl={rfdigital}
          />
        </Grid>
        <Grid item md={6} lg={6}>
          <ServiceCard
            link="/rf-iot"
            title="IoT Services"
            subTitle="Our IoT services help our clients unlock new business opportunities. This includes designing unique/custom solutions fit your requirements, developing prototype, validating the concept, productizing it, scaling and deploying/installation and maintenance and support services. "
            imgUrl="https://ak.picdn.net/shutterstock/videos/1015323406/thumb/12.jpg"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceComponent;
