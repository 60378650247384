import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import rfcheckout_1 from "../../../assets/products/rfdigital_1.png";
import rfcheckout_2 from "../../../assets/products/rfdigital_2.png";
const HowitsWorkSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box
      id="how-its-work-section"
      component="div"
      className={classes.howItsWorksSectionBox}
    >
      <Box pb={{ xs: 2, md: 5, lg: 5, xl: 5 }}>
        <Typography
          classes={{ root: classes.howItsWorkSectionTitle }}
          variant="h4"
          component="h4"
          align="center"
        >
          How it works
        </Typography>
      </Box>
      <Box component="div" className={classes.contentSectionBox}>
        <Box
          style={{ backgroundImage: `url(${rfcheckout_1})` }}
          className={classes.imageBox}
        ></Box>
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                1
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Digital Shelf Label Hardware
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              Choose from variety of label options, whichever is best suited for
              your specific case, our experts can help you select the best
              option for you.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box component="div" className={classes.contentSectionBoxRight}>
        <Box pr={4} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                2
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Wireless infrastructure and Software
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              Our hardware/software solution helps you connect to the labels
              bi-directionally, with a centralized module help manage the
              inventory and easily update the labels with relevant information
              in real-time. It is simple to deploy, easy to manage and scalable
              and expandable as your business demands.
            </Typography>
          </Box>
        </Box>

        <Box
          style={{ backgroundImage: `url(${rfcheckout_2})` }}
          className={classes.imageBox}
        ></Box>
      </Box>
    </Box>
  );
};

export default HowitsWorkSectionComponent;
