import React from "react";
import { Switch, Route } from "react-router-dom";
import NavigationComponent from "./components/navigation";
import FooterComponent from "./components/footer";
import HomePageScreen from "./screens/HomePage";
import RfbinSpecimenPage from "./screens/RfbinSpecimen";
import RfCheckoutPage from "./screens/RfCheckout";
import RfDigitalPage from "./screens/RfDigital";
import RfIotPage from "./screens/RfIot";
import ScrollTop from "./components/scrollTop";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    if (location.pathname === "/") {
      setValue(0);
    }
    if (location.pathname === "/rfbin") {
      setValue(1);
    }
    if (location.pathname === "/rf-checkout") {
      setValue(2);
    }
    if (location.pathname === "/rf-digital-shelf") {
      setValue(3);
    }
    if (location.pathname === "/rf-iot") {
      setValue(4);
    }
  }, [location]);

  return (
    <section id="app_container">
      <ScrollTop />
      <NavigationComponent tabIndex={value} />
      <Switch>
        <Route exact={true} path="/" component={HomePageScreen} />
        <Route exact={true} path="/rfbin" component={RfbinSpecimenPage} />
        <Route exact={true} path="/rf-checkout" component={RfCheckoutPage} />
        <Route
          exact={true}
          path="/rf-digital-shelf"
          component={RfDigitalPage}
        />
        <Route exact={true} path="/rf-iot" component={RfIotPage} />
      </Switch>
      <FooterComponent tabIndex={value} />
    </section>
  );
};

export default App;
