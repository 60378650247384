import React, { Fragment } from "react";
import IntroSectionComponent from "../components/rfDigital/intro_section";
import ProductInfoSectionComponent from "../components/rfDigital/video_section";
import HowItsWorkComponent from "../components/rfDigital/how_its_work_section";
const RfDigitalScreen = () => {
  return (
    <Fragment>
      <IntroSectionComponent />
      <ProductInfoSectionComponent />
      <HowItsWorkComponent />
    </Fragment>
  );
};

export default RfDigitalScreen;
