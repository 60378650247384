import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
const VideoSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoSectionBox} id="video-section" component="div">
      <Grid spacing={2} style={{ width: "100%", margin: 0 }} container>
        {/* <Grid
          item
          md={5}
          lg={5}
          xl={5}
          xs={12}
          classes={{ root: classes.videoPlayer }}
        >
          <img width="100%" src={rfidCheckout} />
        </Grid> */}
        <Grid
          item
          // md={7}
          // lg={7}
          // xl={7}
          xs={12}
          classes={{ root: classes.productFeatures }}
        >
          <Box pl={3}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.videoSectionTitle}
            >
              Product Features
            </Typography>
          </Box>
          <Box pl={5}>
            <ul className={classes.ul}>
              <li className={classes.li}>
                Centralized administration of displays and tags with full
                visibility
              </li>
              <li className={classes.li}>
                Content flexibility allowing for future updates
              </li>
              <li className={classes.li}>
                Ensures accurate pricing and product information
              </li>
              <li className={classes.li}>
                Enables on-demand, real-time promotions
              </li>
              <li className={classes.li}>
                Improves margin and increases sales
              </li>
              <li className={classes.li}>Reduces labor costs and errors</li>
              <li className={classes.li}>
                Enhances customer loyalty and satisfaction
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VideoSectionComponent;
