import React, { Fragment } from "react";
import IntroSectionComponent from "../components/iot/intro_section";
import HealthcareSectionComponent from "../components/iot/video_section";
import HwItsWorkSectionComponent from "../components/iot/how_its_work_section";
const RfIotPage = () => {
  return (
    <Fragment>
      <IntroSectionComponent />
      <HealthcareSectionComponent/>
      <HwItsWorkSectionComponent/>
    </Fragment>
  );
};

export default RfIotPage;
