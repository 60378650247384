import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Iot2 from "../../../assets/iot_2.png";
const HowitsWorkSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box
      id="how-its-work-section"
      component="div"
      className={classes.howItsWorksSectionBox}
    >
      <Box pb={{ xs: 2, md: 5, lg: 5, xl: 5 }}>
        <Typography
          classes={{ root: classes.howItsWorkSectionTitle }}
          variant="h4"
          component="h4"
          align="center"
        >
          Example
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img className={classes.img} src={Iot2} />
      </Box>
      <Box component="div">
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Typography
            className={classes.leftSideContent}
            variant="subtitle1"
            component="p"
          >
            We have experience integrating with various different{" "}
            <b>EMR providers</b>. Epic, Allscripts, Cerner, Meditech, NextGen
            and many more … <b>Let us know your specific needs</b> we will
            create a custom solution for you.
          </Typography>
        </Box>

        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Typography
            className={classes.leftSideContent}
            variant="subtitle1"
            component="p"
          >
            EMR sends HL7 messages to our custom connector, to add/edit records
            to a preconfigured port. We processes the incoming messages,
            cleanses, and stores the resulting records in the staging table.The
            staging table data can then be exported, sync to any system at your
            end.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HowitsWorkSectionComponent;
