import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import rfbin_11 from "../../../assets/products/rfbin_11.png";
import rfbin_22 from "../../../assets/products/rfbin_22.png";
import rfbin_33 from "../../../assets/products/rfbin_33.png";
const HowitsWorkSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box
      id="how-its-work-section"
      component="div"
      className={classes.howItsWorksSectionBox}
    >
      <Box pb={{ xs: 2, md: 5, lg: 5, xl: 5 }}>
        <Typography
          classes={{ root: classes.howItsWorkSectionTitle }}
          variant="h4"
          component="h4"
          align="center"
        >
          How it works
        </Typography>
      </Box>
      <Box component="div" className={classes.contentSectionBox}>
        <Box
          style={{ backgroundImage: `url(${rfbin_11})` }}
          className={classes.imageBox}
        ></Box>
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                1
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Place shipping package with Bio-sample in the Bin
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              When the shipment is ready drop the package in the Bin. The Bin
              provides audio/visual feedback, to indicate the count of Bio-sample 
              it recognize from he content inside the package
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box component="div" className={classes.contentSectionBoxRight}>
        <Box pr={4} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                2
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                Demand Signals
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              The count of Bio-sample , type of Bio-sample , other related information
              is recorded and displayed on the screen and a demand signal is
              generated from the onboard computer
            </Typography>
          </Box>
        </Box>

        <Box
          style={{ backgroundImage: `url(${rfbin_22})` }}
          className={classes.imageBox}
        ></Box>
      </Box>

      <Box component="div" className={classes.contentSectionBox}>
        <Box
          style={{ backgroundImage: `url(${rfbin_33})` }}
          className={classes.imageBox}
        ></Box>
        <Box pl={{ xs: 0, md: 4, lg: 4, xl: 4 }} className={classes.contentBox}>
          <Box component="div" className={classes.contentLeftTitleBox}>
            <Box className={classes.number}>
              <Typography
                variant="h3"
                component="h3"
                classes={{ root: classes.numberLbl }}
                align="center"
              >
                3
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.titleLabel}
                variant="h6"
                component="h6"
              >
                On-demand Cloud based communication
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.leftSideContent}
              variant="subtitle1"
              component="p"
            >
              A built-in, always-on cellular connectivity, securely establishes
              communication with backend Cloud platform, sends the demand signal
              in real-time, this demand signal can be integrated with Lab
              systems (LIS and LIMS), Physician, Clinical systems and also with
              EMR/EHR systems.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowitsWorkSectionComponent;
