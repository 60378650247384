import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Iot1 from "../../../assets/iot_1.png";
const VideoSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoSectionBox} id="video-section" component="div">
      <Grid spacing={2} style={{ width: "100%", margin: 0 }} container>
        {/* <Grid
          item
          md={5}
          lg={5}
          xl={5}
          xs={12}
          classes={{ root: classes.videoPlayer }}
        >
          <img width="100%" src={rfidCheckout} />
        </Grid> */}
        <Grid
          item
          // md={7}
          // lg={7}
          // xl={7}
          xs={12}
          classes={{ root: classes.productFeatures }}
        >
          <Box pl={3}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.videoSectionTitle}
            >
              Healthcare Ecosystem integration services
            </Typography>
          </Box>
          <Box pl={3}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.videoSectionTitle}
            >
              EMR / EHR integrations
            </Typography>
          </Box>
          <Box pl={3}>
            <Typography classes={{ root: classes.li }}>
              Our experts understand deeply about these systems, we help share
              them across different health care settings. Records are shared
              through network-connected, enterprise-wide information systems or
              other information networks and exchanges. EHRs may include a range
              of data, including demographics, medical history, medication and
              allergies, immunization status, laboratory test results, radiology
              images, vital signs, personal statistics like age and weight, and
              billing information.
            </Typography>
          </Box>

          <Box pt={2} pl={3}>
            <Typography
              variant="h5"
              component="h5"
              className={classes.videoSectionTitle}
            >
              Implementation Options
            </Typography>
          </Box>

          <Box pl={3}>
            <Typography classes={{ root: classes.li }}>
              There are many ways we can make your systems talk to EMRs.
            </Typography>
          </Box>
          <Box pl={5}>
            <ul className={classes.ul}>
              <li className={classes.li}>HL7 messages</li>
              <li className={classes.li}>Nightly database dumps </li>
            </ul>
          </Box>

          <Box pb={2} pl={3}>
            <Typography classes={{ root: classes.li }}>
              We understand HL7 and know how to write custom integration to fit
              our client needs.
            </Typography>
          </Box>
          <Box pl={3}>
            <img src={Iot1} className={classes.imageSection} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VideoSectionComponent;
