import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const IntroSectionComponent = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.introSectionMainBox}>
          <Box id="rfbin-intro-section" component="div">
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h4"
              component="h4"
            >
              Digital Shelf and Signage for Retail
            </Typography>
          </Box>
          <Box>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            ></Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Digital Shelf Labels are a fully customizable e-paper display,
              highlighting prices, advertisements, and any other desired
              information. Allows retails store in real-time optimize the price
              and precision, store efficiency, and enable trust between the
              retailer and customer through transparent pricing.
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Retailers can react to the competition and market conditions
              swiftly and adopt more quickly and earn customer business, adjust
              product prices to correlate with shopper trends throughout the day
              within minutes.Increase business by offering tough to beat loyalty
              programs, discounts to attract customers and make them your repeat
              customer.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default IntroSectionComponent;
