import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const IntroSectionComponent = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.introSectionMainBox}>
          <Box id="rfbin-intro-section" component="div">
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h4"
              component="h4"
            >
              IoT Services
            </Typography>
          </Box>
          <Box>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Our IoT services help our clients unlock new business
              opportunities. This includes designing unique/custom solutions fit
              your requirements, developing prototype, validating the concept,
              productizing it, scaling and deploying/installation and
              maintenance and support services. We have successfully developed
              and deployed many IoT projects/products for our fortune 500
              customers across CPG/Retail, Healthcare verticals.
            </Typography>
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h5"
              component="h5"
            >
              Consulting services
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Customized IoT programs based on industry and business use case.
              Strategy roadmap and consulting for transforming business models,
              solution architectures (micro-services based) for device
              management and operations.
            </Typography>
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h5"
              component="h5"
            >
              Engineering services
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Sensors, connectivity, connectivity protocols, edge analytics,
              interoperability, IoT platform, gateway framework, data
              processing, Communication protocols, message brokers, IoT
              security, Application and analytics, cloud enablement, device
              lifecycle management.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default IntroSectionComponent;
