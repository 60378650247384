import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = createBreakpoints({});
const PRIMARY_COLOR = "#212121";
const SECONDARY_COLOR = "#4769a0";

const theme = createTheme({
  typography: {
    h5: {
      [breakpoints.only("xs")]: {
        fontSize: 16,
        fontWeight: 500,
      },
    },
    h4: {
      fontSize: 30,
      fontWeight: 300,
      [breakpoints.only("xs")]: {
        fontSize: 20,
        fontWeight: 900,
      },
    },
    subtitle1: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: `100px !important`,
        fontSize: 12,
        letterSpacing: 1,
        //textTransform:'none',
        fontWeight: 300,
        height: 70,
        [breakpoints.between("xs", "sm")]: {
          height: 40,
          fontSize: 14,
        },
      },
      textColorInherit: {
        color: "#fafafa",
      },
      indicator: {
        root: {
          height: 10,
          [breakpoints.only("xs")]: {
            height: 40,
          },
        },
      },
    },
  },
});
export default theme;
