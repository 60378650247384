import React, { Fragment } from "react";
import IntroSectionComponent from "../components/rfcheckout/intro_section";
import VideoSectionComponent from "../components/rfcheckout/video_section";
import HowItsWorkSectionComponent from "../components/rfcheckout//how_its_work_section";

export const RfCheckoutScreen = () => {
  return (
    <Fragment>
      <IntroSectionComponent />
      <VideoSectionComponent />
      <HowItsWorkSectionComponent />
    </Fragment>
  );
};

export default RfCheckoutScreen;
