import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const IntroSectionComponent = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.introSectionMainBox}>
          <Box id="rfbin-intro-section" component="div">
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h4"
              component="h4"
            >
              RFBin &trade; - Bio-sample Tracking Solution
            </Typography>
          </Box>
          <Box>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              For independent labs keeping the chain of custody of Bio-sample
              from collection point to testing to providing results back to
              their customers, is a crucial part of their workflow. Currently
              there is a huge gap exists at the collection point, this lack of
              information at collection point costing Independent Labs loss of
              revenue, loss of customers, etc..
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Introducing RFBin &trade;, a Bio-sample /sample collection bin
              with built-in technology that automatically identifies the number
              of Bio-sample /samples that are ready to be shipped from physician
              office / clinics, sends the demand signal to the lab in real-time,
              so labs now can optimize their labor and operations, machinery
              based on this forecasted demand, thus increases the productivity,
              provides visibility and great customer satisfaction by timely
              processing and producing the results for their customers.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default IntroSectionComponent;
