import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  howItsWorksSectionBox: {
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: "15%",
    paddingRight: "15%",
    backgroundColor: "#FAFAFA",
    [theme.breakpoints.only("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  howItsWorkSectionTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 20,
    [theme.breakpoints.only("xs")]: {
      fontWeight: 900,
    },
  },
  contentSectionBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  contentSectionBoxRight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  contentBoxLeft: {
    flex: 1,
    display: "flex",
    //alignItems: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  number: {
    minHeight: 40,
    minWidth: 40,
    borderRadius: "100%",
    backgroundColor: theme.palette.secondary.main,
    marginRight: 10,
    [theme.breakpoints.only("xs")]: {
      minWidth: 25,
      minHeight: 25,
      marginTop: 5,
    },
  },
  numberLbl: {
    lineHeight: "40px",
    fontSize: 20,
    fontWeight: 900,
    color: theme.palette.common.white,
    [theme.breakpoints.only("xs")]: {
      lineHeight: "25px",
      fontSize: 16,
    },
  },
  titleLabel: {
    width: "100%",
    lineHeight: "30px",
    fontSize: 20,
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  contentLeftTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: 0,
    [theme.breakpoints.only("xs")]: {
      marginTop: 20,
    },
  },
  contentBoxRight: {
    flex: 1,
    display: "flex",
    paddingLeft: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  leftSideContent: {
    paddingLeft: 50,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },

  contentBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  imageBox: {
    display: "flex",
    flex: 1,
    backgroundPosition: "top center",
    WebkitBackgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: 300,
    minWidth: 300,
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      minWidth: 320,
    },
  },
}));

export default useStyles;
