import React from "react";
import AboutUsComponent from "../components/aboutus";
import ServiceComponent from "../components/service";
import OurTeamsComponent from "../components/ourteam";

const HomePageScreen = () => {
  return (
    <React.Fragment>
      <AboutUsComponent />
      <ServiceComponent />
      <OurTeamsComponent />
    </React.Fragment>
  );
};

export default HomePageScreen;
