import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const IntroSectionComponent = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.introSectionMainBox}>
          <Box id="rfbin-intro-section" component="div">
            <Typography
              classes={{ root: classes.introSectionTitle }}
              variant="h4"
              component="h4"
            >
              RFCheckout – Self Checkout for Retail
            </Typography>
          </Box>
          <Box>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Touchless checkout, give your customers speed and convenience of
              grab and go by eliminating the long lines, painful checkout
              process. No barcodes to scan, no attendant to see, grab the items
              place them on the designated checkout area, get total amount to
              checkout, pay using your mobile and walk out. We are reimagining
              the retail check out using our simple to use technology solutions.
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              Our technology platform provides unique identification tags that
              you can place on your individual items, and when placed on the
              checkout counter, it can read thousands of items instantly and
              provides checkout experience very simple and intuitive.
            </Typography>
            <Typography
              classes={{ root: classes.introContent }}
              variant="subtitle1"
              component="p"
            >
              In the current environment its very tough to find labor to work in
              retail establishment, with this technology you can eliminate the
              labor needed for checkout, re-allocate them for inventory, shelf
              stocking and out of stock audit purposes. It saves our Retail
              customers thousands of dollars in labor cost and provide efficient
              and seamless experience to their customers.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default IntroSectionComponent;
