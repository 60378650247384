import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "react-slick";

import client_1 from "../../assets/ourClients/01.png";
import client_2 from "../../assets/ourClients/02.png";
import client_3 from "../../assets/ourClients/03.png";
import client_4 from "../../assets/ourClients/04.png";
import client_5 from "../../assets/ourClients/05.png";
import client_6 from "../../assets/ourClients/06.png";
import client_7 from "../../assets/ourClients/07.png";
import client_8 from "../../assets/ourClients/08.png";
import client_9 from "../../assets/ourClients/09.png";

const useStyles = makeStyles((theme) => ({
  outTeamsSection: {
    paddingTop: 80,
    paddingBottom: 130,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  teamCard: {
    width: "100%",
    //minHeight: "200px",
    //backgroundColor: theme.palette.primary.main,
    margin: 10,
    padding: 10,
  },
  outTeamsText: {
    textTransform: "uppercase",
    fontWeight: 500,
    [theme.breakpoints.only("xs")]: {
      fontWeight: 900,
    },
  },
  profileImage: {
    minHeight: 250,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
  },
  profileName: {
    color: "#fafafa",
    textAlign: "center",
  },
  profileDesignation: {
    color: "gray",
    fontStyle: "italic",
    fontWeight: 300,
  },
}));

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProfileCard = ({ imgUrl, title, subTitle }) => {
  const classes = useStyles();
  return (
    <Box component="div" p={1}>
      <div className={classes.teamCard}>
        <img width="80%" src={imgUrl} />
      </div>
    </Box>
  );
};
const OurTeam = () => {
  const classes = useStyles();
  return (
    <Box className={classes.outTeamsSection} id="out-teams">
      <Box mb={2}>
        <Typography
          classes={{ root: classes.outTeamsText }}
          variant="h4"
          component="h4"
          align="center"
        >
          Our clients
        </Typography>
      </Box>
      <Slider {...settings}>
        <ProfileCard imgUrl={client_1} />
        <ProfileCard imgUrl={client_2} />
        <ProfileCard imgUrl={client_3} />
        <ProfileCard imgUrl={client_4} />
        <ProfileCard imgUrl={client_5} />
        <ProfileCard imgUrl={client_6} />
        <ProfileCard imgUrl={client_7} />
        <ProfileCard imgUrl={client_8} />
        <ProfileCard imgUrl={client_9} />
      </Slider>
    </Box>
  );
};

export default OurTeam;
