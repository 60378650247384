import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  introSectionMainBox: {
    padding: 80,
    [theme.breakpoints.only("xs")]: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
    backgroundColor:theme.palette.common.white
  },
  introSectionTitle: {
    fontWeight: 500,
    width: "70%",
    marginBottom: 20,
    [theme.breakpoints.only("xs")]: {
      fontWeight: 900,
      width: "100%",
    },
  },
  introContent: {
    width: "85%",
    marginBottom: 20,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    }
  },
}));

export default useStyles;
