import React from "react";
import Box from "@material-ui/core/Box";
import { Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import rfidImage from "../../assets/rfidImage.png";

const useStyles = makeStyles((theme) => ({
  sectionMainBox: {
    minHeight: 535,
    width: "100%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
  },

  leftSideSection: {
    flex: 3,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: 80,
    paddingRight: 10,
    [theme.breakpoints.only("xs")]: {
      position: "relative",
      flex: 1,
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  rightSideSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 2.5,
    paddingRight: 20,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },

  mobileImageSection: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    height: "100%",
    // width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  aboutUsText: {
    textTransform: "uppercase",
    fontWeight: 500,
    [theme.breakpoints.only("xs")]: {
      fontWeight: 900,
    },
  },

  rfidImage: {
    width: "100%",
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionMainBox} id="about-section">
      <Box className={classes.leftSideSection}>
        <Box mb={2}>
          <Typography
            classes={{ root: classes.aboutUsText }}
            variant="h4"
            component="h4"
          >
            About Us
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" component="p">
            <b>Our mission:</b> To help our customers solve important problems
            by applying emerging technology. We solve most complex problems with
            simple yet powerful software and hardware by creating engaging,
            intuitive experiences for our customers and their end users.
          </Typography>
          <Box mt={2} mb={2}>
            <Typography variant="subtitle1" component="p">
              <b>Spektrumn </b>Technologies built their reputation by constantly
              obsessing about our customer problems by offering high quality
              software and hardware solutions{" "}
              <Hidden only={["lg", "md", "sm", "xl"]}>
                <img
                  alt="rfid-info-graphic"
                  className={classes.rfidImage}
                  src={rfidImage}
                ></img>
              </Hidden>
              and products in Retail, CPG, Healthcare verticals by applying
              vision, <b>RFID, AI/ML, IOT</b> technologies, we also offer SaaS
              product development, web and front-end technology solutions.{" "}
              <Typography />
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="p">
              We pride ourselves being a reliable software solutions & service
              provider. Our experience in the field of specialized integrated
              projects is rooted on our dynamic commitment to meet the growing
              needs of the industries we operate. We take immense pride that we
              are a team of young dynamic professional dedicated towards the
              delivery of quality products and solution to achieve the utmost
              customer satisfaction. We specialize in providing integrated
              solutions and services to large, medium & small size companies all
              around the world.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightSideSection}>
        <img
          alt="rfid-info-graphic"
          className={classes.rfidImage}
          src={rfidImage}
        ></img>
      </Box>
    </Box>
  );
};

export default AboutUs;
