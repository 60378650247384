import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  videoSectionBox: {
    padding: 80,
    [theme.breakpoints.only("xs")]: {
      padding: 0,
    },
    backgroundColor: theme.palette.common.white,
  },
  videoPlayer: {
    //minHeight: 300,
    [theme.breakpoints.only('xs')]:{
      padding:`0px !important`
    }
  },
  productFeatures: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: `30px !important`,
      paddingBottom: "40px !important",
      paddinLeft:`20px !important`,
      paddingRight:`20px !important`
    },
  },
  videoSectionTitle: {
    fontWeight: 500,
    width: "70%",
    marginBottom: 20,
    [theme.breakpoints.only("xs")]: {
      fontWeight: 900,
    },
  },
  ul: {
    padding: 0,
  },
  li: {
    ...theme.typography.subtitle1,
    padding: 0,
    marginBottom: 5,
  },
}));

export default useStyles;
