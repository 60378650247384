import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReactPlayer from "react-player";
import useStyles from "./styles";
import rbinVideo from "../../../assets/RFBin-video.mp4";
const VideoSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoSectionBox} id="video-section" component="div">
      <Grid spacing={2} style={{ width: "100%", margin: 0 }} container>
        <Grid
          item
          md={5}
          lg={5}
          xl={5}
          xs={12}
          classes={{ root: classes.videoPlayer }}
        >
          <ReactPlayer
            width="100%"
            height="100%"
            controls={true}
            url={rbinVideo}
          />
        </Grid>
        <Grid
          item
          md={7}
          lg={7}
          xl={7}
          xs={12}
          classes={{ root: classes.productFeatures }}
        >
          <Box pl={3}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.videoSectionTitle}
            >
              Product Features
            </Typography>
          </Box>
          <Box pl={5}>
            <ul className={classes.ul}>
              <li className={classes.li}>
                Countertop model, easy to access by all staff members at
                collection point
              </li>
              <li className={classes.li}>
                It’s a simple Plug and Play, no installation, setup is required
              </li>
              <li className={classes.li}>
                No internet connection required, built-in cellular connectivity
              </li>
              <li className={classes.li}>
                Intuitive audio, visual and graphic interface to interact with
                the device
              </li>
              <li className={classes.li}>
                Small in size, easily can blend in with the environment
              </li>
              <li className={classes.li}>
                Easy to drop the packages in and remove the packages{" "}
              </li>
              <li className={classes.li}>
                Built with healthcare customers in mind
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VideoSectionComponent;
