import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import rfidCheckout from "../../../assets/products/rfid_checkout.png";
const VideoSectionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoSectionBox} id="video-section" component="div">
      <Grid spacing={2} style={{ width: "100%", margin: 0 }} container>
        <Grid
          item
          md={5}
          lg={5}
          xl={5}
          xs={12}
          classes={{ root: classes.videoPlayer }}
        >
          <img width="100%" src={rfidCheckout} />
        </Grid>
        <Grid
          item
          md={7}
          lg={7}
          xl={7}
          xs={12}
          classes={{ root: classes.productFeatures }}
        >
          <Box pl={3}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.videoSectionTitle}
            >
              Product Features
            </Typography>
          </Box>
          <Box pl={5}>
            <ul className={classes.ul}>
              <li className={classes.li}>
                Provides seamless shopping experience to your customers
              </li>
              <li className={classes.li}>
                Save time, effort for your customers in the checkout process
              </li>
              <li className={classes.li}>
                Technology platform that is secure, reliable and scalable for
                your needs
              </li>
              <li className={classes.li}>
                Empower users with self service capability
              </li>
              <li className={classes.li}>
                Industry leading RFID technology with Cloud platform
              </li>
              <li className={classes.li}>
                Integrates with existing POS systems
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VideoSectionComponent;
